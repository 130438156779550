
import { MESSAGE, RTYPE } from '../../constants/actionTypes';
import * as api from '../../api/index.js';

export const resort_type_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.resort_type_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const resort_type_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.resort_type_update(formData);
      dispatch({ type: MESSAGE, payload:data });
    } catch (error) {
      console.log(error);
    }
  };
  // export const genre_delete = (formData) => async (dispatch) => {
  //   try {
  //     const { data } = await api.genre_delete(formData);
  //     dispatch({ type: MESSAGE5, payload:data });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  export const resort_type_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.resort_type_list(formData);
      
      dispatch({ type: RTYPE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  