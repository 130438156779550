import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Export from "../utils/Export";
import Reload from "../utils/Reload";

import {
  customer_delete,
  customer_update,
  all_customer_list,
} from "./../../actions/customer";
import { all_subscription_list } from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
// import { all_seller_list } from "../../actions/seller";
import { resort_manager_list } from "../../actions/resory_manager";
export default function ResortManager() {
  const dispatch = useDispatch();

  const [tableData, setTableData] = useState({
    tableTitle: "Resort Manager",
    deleteRecord: customer_delete,
    updateRecord: customer_update,
    onDeleteText: "Are you sure want to delete this customer?",
    disableDelete: true,
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure you want to activate this customer?",
    onInactiveText: [
      "Are you sure you want to inactivate this customer? ",
      "The customer will no longer be logged into the system once you inactivate it.",
    ],
    tableHead: [
      {
        id: "person_name",
        label: "Name",
        link: "/ResortManager/ResortManagerDetail/ResortManagerDetail",
        // subText: "isActive1",
        color: "var(--gradientColor2)"
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "mobile",
        label: "Mobile No",
      },
      // {
      // 	id: "subscription",
      // 	label: "Subscription",
      // },
      // {
      // 	id: "deviceType",
      // 	label: "Device",
      // 	subText: "deviceId"
      // },
      // {
      // 	id: "userLocation",
      // 	label: "Location",
      // },
      {
        id: "created_at",
        label: "Date",
      },
      // {
      // 	id: "status",
      // 	label: "Status",
      // }
    ],
    tableBody: [],
    filterColumn: [
      // {
      // 	id: "1",
      // 	title: "Plan Type",
      // 	name: "subscription",
      // 	options: ["FREE", "TVOD", "SVOD"],
      // },
    ],
    isDateRangeFilter: "created_at",
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const resort_manager = useSelector((state) => state?.resort_manager?.resort_manager_list);
  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  const message = useSelector((state) => state.layout.message);

  useEffect(() => {
    dispatch(all_subscription_list());
    dispatch(resort_manager_list());
  }, [message]);
  useMemo(() => {
    if (resort_manager?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = resort_manager?.data.map((ele) => ({
        ...ele,
        // name: (
        //   <>
        //     <span style={{ fontWeight: "700" }}>{ele?.resort_name}</span>{" "}
        //     <span style={{ fontWeight: "700" }}>{ele?.person_name}</span>
        //   </>
        // ),
        // subscription:
        //   ele?.subscription !== null ? ele?.subscription?.plan_name : " - ",
        mobile: ele?.mobileNumber !== null ? ele?.mobileNumber : " - ",
        // socialType1: ele?.socialType !== null ? "Gmail" : "Mobile",
        // isActive1:
        //   ele?.isActive == "false" ? (
        //     <>
        //       <span style={{ color: "red" }}>Not Verified</span>
        //     </>
        //   ) : (
        //     <>
        //       <span style={{ color: "green" }}>Verified</span>
        //     </>
        //   ),
      }));
      setTableData({ ...temp });
    }
  }, [resort_manager]);
  // console.log(customers,"3333")
  // useMemo(() => {

  // 	if (subscriptions?.statuscode == 200) {
  // 		const temp = tableData
  // 		temp.filterColumn[0]["options"] = subscriptions?.data.map((ele) => (ele?.plan_name))
  // 		setTableData({ ...temp })
  // 	}
  // }, [subscriptions])

  return (
    <>
      <Export
        fileName={"Resort Manager"}
        exportData={tableData?.exportData || tableData?.tableBody}
        headings={tableData.tableHead.map((value) => value.label)}
      />

      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
