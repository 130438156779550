import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import FoodOption from '../components/Masters/FoodOption/FoodOption';

function FoodOptionModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateFoodOption' element={<Layout children={FoodOption()} />} />
                < Route path='/EditFoodOption' element={<Layout children={FoodOption()} />} />
                < Route path='/FoodOption' element={<Layout children={FoodOption()} />} />
            </Routes>
        </div>
    )
}
export default FoodOptionModule