import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Facilities from '../components/Masters/Facilities/Facilities';

function FacilitieModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateFacilities' element={<Layout children={Facilities()} />} />
                < Route path='/EditFacilities' element={<Layout children={Facilities()} />} />
                < Route path='/Facilities' element={<Layout children={Facilities()} />} />
            </Routes>
        </div>
    )
}
export default FacilitieModule