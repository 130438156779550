import React from 'react';
import { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
export default function MoviesDownload(data) {
  console.log(data,"nsdfsdf")
  const location = useLocation()
  const [tableData, setTableData] = useState({
    tableTitle: "Product Submission List",
    disableDelete: true,
    tableHead: [

      {
        id: 'resort_name',
        label: 'Product Name',
        // isSpecial:true,
        // align:"left"
        link:"/VideoSubmission/VideoSubmissionDetails/",
        color: "var(--gradientColor2)",

      },
      {
        id: 'resort_type',
        label: 'Resort name',
      },
     
      {
        id: 'created_at',
        label: 'Created At',
      },
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true,
      },
    ],
    tableBody: [

    ]
  })
  const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  useMemo(() => {
    if (data && Array.isArray(data.data)) { // Check if data.data is an array
      const newData = data?.data?.map((state) => ({
        ...state,
        resort_name: state?.resort_name?.length <= 40 ? state?.resort_name : state?.resort_name?.substring(0, 40) + "...",
        resort_type: state?.resort_type?.resort_type_name,
        product_price : parseFloat(state?.product_price).toFixed(2)
        // video_language: state?.video?.video_language,
        // video_redirect_count: state?.count
      }));
      setTableData({ ...tableData, tableBody: newData });
    } else {
      setTableData({ ...tableData, tableBody: [] });
    }
  }, [data]);
  return (
    <>




      <ListTable tableData={tableData} key={"ListTable"} setForm={setForm} setTableData={setTableData} setIsEdit={setIsEdit} />


    </>
  );
}
