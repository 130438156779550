import React, { useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import styles from "./Authentication.module.css";
import './Authentication.css'
import favicon from "./../../images/Change_pswd.png"
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Otp_verify } from "../../actions/authenticate";
import { forgot_password } from "../../actions/authenticate";
import EmailIcon from '@mui/icons-material/Email';
import { InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
const ForgotPasswordForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpPage, setOtpPage] = useState(false)
  const [otp, setOtp] = React.useState('')

  const [loading1, setLoading1] = React.useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue)
  }
  const handleSubmit1 = (event) => {
    event.preventDefault();
    setLoading1(true)

    dispatch(Otp_verify({ username: email, OTP: otp }))


  };

  const message1 = useSelector((state) => state.layout.message1);
  // console.log(location, "33333")

  useMemo(() => {
    if (message1?.statuscode == 200) {
      setTimeout(() => {
        setLoading1(true)



        navigate("/Authentication/UpdatePassword", { state: { username: "raviottdev@gmail.com" } })


      }, 1000)
    }
    else {
      setLoading1(false)
    }
  }, [message1])

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setError(newEmail !== 'raviottdev@gmail.com');
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === 'raviottdev@gmail.com') {

      setLoading(true);
      const data = new FormData(event.currentTarget);
      setTimeout(() => {
        dispatch(forgot_password({
          username: data.get("username"),
        }))
      }, 1000);


    }
    else {
      setError(true);
    }
    // Navigate("/Dashboard")
  };
  const message = useSelector((state) => state.layout.message);
  // console.log(message, "33333")

  useMemo(() => {
    // console.log(event,"88888")
    if (message?.statuscode == 200) {
      // const data = new FormData(event.currentTarget);
      // setLoading(true)
      setTimeout(() => {
        // navigate("/Authentication/Otp", { state: { username: "behindwoods.master@gmail.com" } })
        setLoading(false)
        setOtpPage(true)

      }, 1000)
    }
    else {
      setLoading(false)
    }
  }, [message])

  return (
    !otpPage ?
      <>
        <div className={styles.authenticationBox + " dark"}>
          <Box
            component="main"
            sx={{
              maxWidth: "450px",
              // mr: "10%",
              padding: "20px",
              borderRadius: "10px",
              // boxShadow: "var(--gradientColor2) -2px -2px 5px 1px,var(--gradientColor2) -2px 2px 5px 1px,var(--gradientColor2) 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              // boxShadow: "var(--gradientColorLighter2) 0px 6px 5px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
              // boxShadow: "#dca010 -2px -2px 5px 1px,#dca010 -2px 2px 5px 1px,#dca010 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              boxShadow: "#8b0272 -2px -2px 5px 1px,#8b0272 -2px 2px 5px 1px,#8b0272 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",

            }}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box>


                <Box component="form" sx={{ backgroundColor: "white", borderRadius: "10px", p: "20px" }} noValidate onSubmit={handleSubmit}>
                  <Grid container justifyContent={"center"} sx={{ mb: 3 }}>
                    <img
                      src={favicon}
                      alt="favicon"
                      className={styles.favicon}
                      width={"100px"}
                    /></Grid>
                  <Typography as="h1" fontSize="24px" textAlign={"center"} fontWeight="700" mb="5px">
                    Forget Password

                  </Typography>

                  <Typography fontSize="15px" mb="5px" >
                    Don't worry! It happens. Please enter the Email associated with your account
                  </Typography>
                  <Box
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "10px",
                      mb: "20px",
                    }}
                    className="bg-black"
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          component="label"
                          sx={{
                            fontWeight: "500",
                            fontSize: "14px",
                            mb: "10px",
                            display: "block",
                          }}
                          onCha

                        >
                          Email
                        </Typography>

                        <TextField
                          required
                          fullWidth
                          id="username"
                          label="Email Address"
                          name="username"
                          autoComplete="username"
                          onChange={handleEmailChange}
                          InputProps={{
                            style: { borderRadius: 8 },
                            endAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon style={{ color: "black !important" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {error && <p style={{ color: 'red', marginLeft: "1rem" }}>Invalid email address</p>}
                    </Grid> {message?.message}
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading || error}
                    sx={{
                      mt: 1,
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      fontWeight: "700",
                      fontSize: "16px",
                      padding: "12px 10px",
                      color: "#fff !important"
                    }}
                    style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}
                  >
                    {loading ? 'Sending...' : 'Send OTP'}
                  </Button>
                  <Box as="div" textAlign="center" mt="20px">
                    <Link
                      to="/Authentication/SignIn/"
                      className={"primaryColor text-decoration-none " + styles.whiteText}
                    >
                      <i className="ri-arrow-left-s-line"></i> Back to Sign in
                    </Link>

                  </Box>
                </Box>


              </Box>
            </Grid>
          </Box>
        </div>
        {/* <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <a href="https://www.regalott.com" target="_blank" style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}> REGAL OTT</a>  all rights reserved</p> */}
      {/* <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <span style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>Tmart Platform Private Limited</span>  all rights reserved</p> */}
      <p className={styles.authFooter}>Designed and Developed by  <a href="https://icode49.com/" style={{color:"#e35466" , marginLeft:"5px" , marginRight:"5px"}} target="_blank"> iCode49 Technolabs.</a> All rights reserved 2024
      </p>
      </>
      :
      <>
        <div className={styles.authenticationBox + " dark"}>
          <Box
            component="main"
            sx={{
              maxWidth: "450px",
              // mr: "10%",
              padding: "20px",
              borderRadius: "10px",
              // boxShadow: "var(--gradientColor2) -2px -2px 5px 1px,var(--gradientColor2) -2px 2px 5px 1px,var(--gradientColor2) 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              // boxShadow: "var(--gradientColorLighter2) 0px 6px 5px -2px, var(--gradientColorLighter1) 0px 3px 7px -3px",
              // boxShadow: "#dca010 -2px -2px 5px 1px,#dca010 -2px 2px 5px 1px,#dca010 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",
              boxShadow: "#8b0272 -2px -2px 5px 1px,#8b0272 -2px 2px 5px 1px,#8b0272 2px -2px 5px 1px, var(--gradientColor1) 2px 2px 5px 1px, var(--gradientColor1) -2px 2px 5px 1px, var(--gradientColor1) 2px -2px 5px 1px",


            }}
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Box>


                <Box component="form" sx={{ backgroundColor: "white", borderRadius: "10px", p: "20px" }} noValidate
                  onSubmit={handleSubmit1}
                >
                  <Grid container justifyContent={"center"} sx={{ mb: 3 }}>
                    <img
                      src={favicon}
                      alt="favicon"
                      className={styles.favicon}
                      width={"100px"}
                    /></Grid>
                  <Typography as="h1" fontSize="24px" textAlign={"center"} fontWeight="700" mb="5px">
                    Forget Password

                  </Typography>

                  <Typography fontSize="15px" mb="5px" textAlign={"center"} >
                    Please enter one time password recived on raviottdev@gmail.com
                  </Typography>
                  <Box
                    sx={{
                      padding: "10px 20px",
                      borderRadius: "10px",
                      mb: "20px",
                    }}
                    className="bg-black"
                  >
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        {/* <Typography
                      component="label"
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        mb: "10px",
                        display: "block",
                      }}
                    >
                      Otp
                    </Typography> */}

                        {/* <TextField
                      required
                      fullWidth
                      id="username"
                      label="Email Address"
                      name="username"
                      autoComplete="username"
                      InputProps={{
                        style: { borderRadius: 8 },
                        endAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon style={{ color: "black !important" }} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                        <MuiOtpInput value={otp} onChange={handleChange} />
                      </Grid>

                    </Grid> {message1?.message}
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading1}
                    sx={{
                      mt: 1,
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      fontWeight: "500",
                      fontSize: "16px",
                      padding: "12px 10px",
                      color: "#fff !important"
                    }}
                    style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}
                  // onSubmit={handleOtp}
                  >
                    Submit
                  </Button>
                  <Box as="div" textAlign="center" mt="20px">
                    <Link
                      to="/Authentication/SignIn/"
                      className={"primaryColor text-decoration-none " + styles.whiteText}
                    >
                      <i className="ri-arrow-left-s-line"></i> Back to Sign in
                    </Link>

                  </Box>
                </Box>


              </Box>
            </Grid>
          </Box>
        </div>
        // <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <span style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>MYOTT</span>  all rights reserved</p>
      // <p className={styles.authFooter}>Copyright © {new Date().getFullYear()} <span style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>Tmart Platform Private Limited</span>  all rights reserved</p>
      <p className={styles.authFooter}>Designed and Developed by  <a href="https://icode49.com/" style={{color:"#e35466" , marginLeft:"5px" , marginRight:"5px"}} target="_blank"> iCode49 Technolabs.</a> All rights reserved 2024
      </p>
     </>
  );
};

export default ForgotPasswordForm;
