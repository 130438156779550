import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CategoryModule from "./CategoryModule";
import SubCategoryModule from "./SubCategoryModule";
import LanguageModule from "./LanguageModule";
import TagModule from "./TagModule";
import AreaModule from "./Area";
import CityModule from "./City";
import LocalityModule from "./LocalityModule";
import ResortTypeModule from "./ResortTypeModule";
import FacilitieModule from "./FacilitieModule";
import FoodOptionModule from "./FoodOptionModule";
function MastersModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Category/*" element={<CategoryModule key="Category" />} />
        <Route
          path="/SubCategory/*"
          element={<SubCategoryModule key="SubCategory" />}
        />
        <Route path="/Language/*" element={<LanguageModule key="Language" />} />
        <Route
          path="/ResortType/*"
          element={<ResortTypeModule key="ResortType" />}
        />
        <Route path="/Tag/*" element={<TagModule key="Tag" />} />
        <Route path='/Facilities/*' element ={<FacilitieModule key="Facilities"/>}/>
        <Route path='/FoodOption/*' element= {<FoodOptionModule key="FoodOption"/>}/>
        <Route path="/Locality/*" element={<LocalityModule key="Locality" />} />
      </Routes>
    </div>
  );
}

export default MastersModule;
