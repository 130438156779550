import { RMANAGER, RMANAGERS } from "../constants/actionTypes";
import * as api from "../api/index.js";

export const resort_manager_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.resort_manager_list(formData);
    dispatch({ type: RMANAGERS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const resort_manager_detail = (formData) => async (dispatch) => {
  try {
    const { data } = await api.resort_manager_detail(formData);
    dispatch({ type: RMANAGER, payload: data });
  } catch (error) {
    console.log(error);
  }
};
