import React from "react";
import { useState, useMemo } from "react";
import ListTable from "../../utils/Table";

export default function RecentlyAddedMovie({ recently_added_data }) {
  // console.log(recently_added_data, "110110")
  const [tableData, setTableData] = useState({
    tableTitle: "Recently Added Videos",
    disableDelete: true,
    rowsPerPage: 5,
    // fixedRow:5,
    tableHead: [
      {
        id: 'video_name',
        label: 'Resort Name',
        link: "/Video/VideoDetails",
        color: "var(--gradientColor2)",
        // subText: "video_subcategory",
        width: "auto",
      },
      {
        id: "video_end_date",
        label: "Video End Date"
      },
      {
        id: 'video_poster_1',
        label: 'Image',
        isImage: true
      },



      // {
      //   id: 'movie_ownership',
      //   label: 'Ownership',
      // },
      ,
      {
        id: 'video_language',
        label: 'Language',
      },
      {
        id:'video_end_date',
        label:'End Date'
      }

    ],
    tableBody: recently_added_data || []
  })

  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);





  useMemo(() => {

    if (recently_added_data != undefined) {
      const temp = tableData
      temp.tableBody = recently_added_data || []

      setTableData({ ...temp })
    }
  }, [recently_added_data])



  return (
    <>


      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />

    </>
  );
}
