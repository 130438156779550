import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import ResortManager from "../components/ResortManager/ResortManager";
import ResortManagerDetail from "./ResortManagerDetailModule"

function ResortManagerModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/ResortManager" element={<Layout children={ResortManager()} />} />
        {/* {/* <Route
          path="/CreatePromotion"
          element={<Layout children={Promotion()} />}
        /> */}
        <Route
          path="/ResortManagerDetail/*"
          element={<ResortManagerDetail key="ResortManagerDetail" />}
        /> 
      </Routes>
    </div>
  );
}

export default ResortManagerModule;
