import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import MultiStepForm from "../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  video_create,
  movie_delete,
  movie_update,
  all_movie_list,
} from "../../actions/Movie/video";

import { all_category_list } from "../../actions/Masters/category";
import { all_subcategory_list } from "../../actions/Masters/subcategory";
import { all_genre_list, genre_create } from "../../actions/Masters/genre";
import { all_language_list } from "../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Export from "../utils/Export";
import Reload from "../utils/Reload";
import { useLocation, useNavigate } from "react-router-dom";
import Form from "../utils/Form";
import PopupForm from "../utils/PopupForm"
import { city_list, state_list } from "../../actions/city";
import { area_list } from "../../actions/Masters/area";
import { locality_list } from "../../actions/Masters/locality";
import { resort_type_list } from "../../actions/Masters/resortType";
import { facilities_list } from "../../actions/Masters/facilitie";
import { book_option_list, food_option_list } from "../../actions/Masters/foodOption";
export default function Video() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const categories = useSelector((state) => state.masters.categories);
  const [tableData, setTableData] = useState({
    ...TableData(),
    deleteRecord: movie_delete,
    onDeleteText: [
      "Are you sure want to delete this video ?",
      "The Customer Will not be able to see this video once you delete it.",
    ],
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure want to Activate the video ?",
    onInactiveText: [
      "Are you sure want to Inactivate the video ?",
      "The Customer Will not be able to see this video once you Inactivate it.",
    ],
    updateRecord: movie_update,
  });

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const tempFormStruct = FormStructure(isEdit);
  const [formGenre , setFormGenre] = useState({})
  const [formTitle, setFormTitle] = useState(
    [
      "Resort Details",
      "Resort Photos",
      // "Audio Language",
      // "Age Restrictions & Downloads",
      // role != "Distributor" && "Advertisement Details",
    ].filter((e) => e)
  );
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const [openPopUp, setOpenPopUp] = useState(false);

  // const [open1, setOpen1] = useState(false);
  const formStructureGenre =
  [
    {
      id: "1",
      type: "inputBox",
      title: "Add Tags",
      name: "tag_title",
      maxLength: 15,
      regex: /^[a-zA-Z\s\&\-]+$/,
      required: true,
      size:"12"
    },
    {
      id: "3",
      type: "image",
      title: "Image",
      name: "tag_image",
      subtitle: "(Resolution : 512px x 512px) *",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      subsubtitle: "Max File Size 1MB",
      size:"12"

    },

    {
      id: "5",
      type: "button",
      title: "Create",
    },
  ]
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "Video" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        status: "Active",
        smoking_zone:"Yes"
      }
    );
    setIsEdit(path == "EditVideo");
  }, [location]);

  const movies = useSelector((state) => state.movies.movies);
  useMemo(() => {
    if (movies?.statuscode == 200) {
      const temp = tableData;

      temp.tableBody = movies?.data?.map((value, index) => ({
        ...value,
        video_name1 : value?.video_name?.length > 25 ?value?.video_name?.substring(0,25)+"..." :value?.video_name ,
        // genre: value.video_tag?.[0]?.["tag_title"],
        // genre1: value.video_tag?.[1]?.["tag_title"],
        video_tag: value?.video_tag?.map((genre) => genre.tag_title),
        // state : value?.video_area?.state?.state,
        // city : value?.video_area?.city?.city,
        // video_area:value?.video_area?.area,
        video_locality :value?.video_locality?.locality_name,
        booking_type : value?.booking_type?.map((ele)=> ele?.booking_option),
        facility_type : value?.facility_type?.map((ele)=> ele?.facility_type),
        food_type : value?.food_type?.map((ele)=> ele?.food_type),
        resort_type : value?.resort_type?.resort_type_name,
        // resort_type : value?.resort_type?.resort_type_name,

      }));

      setTableData({ ...temp });
      setForm({ ...form });
    }
  }, [movies]);
  // console.log(movies, "525252 ");
  console.log(form,"fsfsd")

  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({ show_notification: "Yes" });
    }
  }, [isEdit]);
  const subcategories = useSelector((state) => state.masters.subcategories);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);
	const genre_msg = useSelector((state) => state?.masters?.genre_msg);
  const State = useSelector((state) => state?.masters?.state_list);
  const facilities = useSelector((state) => state?.masters?.facilities);
  const food_option = useSelector((state) => state?.masters?.food_option);
  const resort_type = useSelector((state) => state.masters.resort_type);
  const booking_option = useSelector((state) => state.masters.booking_option);


  // const City = useSelector((state) => state?.masters?.city_list);
  // const Area = useSelector((state)=>state?.masters?.area_list)
  const Locality = useSelector((state) => state?.masters?.locality_list);
  let count1 = 0;
  useEffect(() => {
    count1++;
    const data = new FormData();
    data.append("id", user?.id);
    if (movies?.statuscode != 200) dispatch(all_movie_list(data));
    // if (subcategories?.statuscode != 200) dispatch(all_subcategory_list());
    // if (categories?.statuscode != 200) dispatch(all_category_list());
    // if (genre?.statuscode != 200) dispatch(all_genre_list());
    if (resort_type?.statuscode !=200) dispatch(resort_type_list());
    if (food_option?.statuscode != 200)   dispatch(food_option_list());
    if (facilities?.statuscode != 200) dispatch(facilities_list());
    if (language?.statuscode != 200) dispatch(all_language_list());
    if (State?.statuscode != 200) dispatch(state_list());
    if (booking_option != 200) dispatch(book_option_list());
    // if (City?.statuscode != 200) dispatch(city_list());
    // if (Area?.statuscode != 200) dispatch(area_list());
    if(Locality?.statuscode != 200) dispatch(locality_list())

  }, [user?.id, isEdit]);

  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      temp[0][25]["display"] = "block";
      setFormStructure([...temp]);
      setView("create_new");
    } else {
      setView("view_all");
      const temp = formStructure;
      temp[0][25]["display"] = "none";
      setFormStructure([...temp]);
      setForm({
        status: "Active",
        smoking_zone : "Yes"
      });
    }
  }, [isEdit, language, location]);

  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][6]["display"] = "block";
      temp[0][7]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][6]["display"] = "none";
      temp[0][7]["display"] = "none";
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
      temp[0][12]["min"] = tomorrow.toISOString().split("T")[0];
      setFormStructure([...temp]);
    }
  }, [form?.publish_type]);

  useMemo(()=>{
    if (form?.publish_type == "Later" && form?.release_date != "" || null || undefined) {
      const temp = formStructure;
      const tomorrow = new Date(form?.release_date);
      tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
      temp[0][12]["min"] = tomorrow.toISOString().split("T")[0];
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
      temp[0][12]["min"] = tomorrow.toISOString().split("T")[0];
      setFormStructure([...temp]);
    }
  },[form?.release_date])
  // useMemo(() => {
  //   if (genre?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[0][4]["options"] = genre?.data.map((genre) => genre?.tag_title);

  //     setFormStructure([...temp]);
  //   }
  // }, [genre]);
  useMemo(() => {
    if (Locality?.statuscode == 200) {
      const temp = formStructure;
      temp[0][8]["options"] = Locality?.data?.map((state) => state?.locality_name);

      setFormStructure([...temp]);
    }
  }, [Locality]);
  useMemo(() => {
    if (resort_type?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = resort_type?.data?.map((state) => state?.resort_type_name);

      setFormStructure([...temp]);
    }
  }, [resort_type]);
  useMemo(() => {
    if (facilities?.statuscode == 200) {
      const temp = formStructure;
      temp[0][2]["options"] = facilities?.data?.map((state) => state?.facility_type);

      setFormStructure([...temp]);
    }
  }, [facilities]);
  useMemo(() => {
    if (food_option?.statuscode == 200) {
      const temp = formStructure;
      temp[0][4]["options"] = food_option?.data?.map((state) => state?.food_type);

      setFormStructure([...temp]);
    }
  }, [food_option]);
  useMemo(() => {
    if (booking_option?.statuscode == 200) {
      const temp = formStructure;
      temp[0][13]["options"] = booking_option?.data?.map((state) => state?.booking_option);

      setFormStructure([...temp]);
    }
  }, [booking_option]);
  // useMemo(() => {
  //   if (City?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[9]["options"] = City?.data?.map((ele)=> form?.state == ele?.state?.state && ele?.city).filter((e) => e)

  //     setFormStructure([...temp]);
  //   }
  // }, [City ,form?.state ]);
  // useMemo(() => {
  //   if (Area?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[10]["options"] = Area?.data.map((ele) => form?.state == ele?.state?.state && form?.city == ele?.city?.city && ele?.area).filter((e) => e);

  //     setFormStructure([...temp]);
  //   }
  // }, [Area ,form?.city]);


  // // console.log(countries,"parthhh")



  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][3]["options"] = language?.data?.map(
        (language) => language?.language_name
      );

      setFormStructure([...temp]);
      // const tempFilter = tableData;
      // tempFilter["filterColumn"][0]["options"] = language?.data?.map(
      //   (language) => language?.language_name
      // );

      // setTableData({ ...tempFilter });
    }
  }, [language]);
  const handleGenreAdd = () => {
    setOpenPopUp(true);
  };
  // useMemo(() => {
  //   const temp = formStructure;
  //   temp[0][4].handlePopUp = handleGenreAdd;
  //   setFormStructure([...temp]);
  // }, []);
  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    // console.log(form,"oiio")
    event.preventDefault();
    const data = new FormData();
    const temp_movie_category = form;
    temp_movie_category["tempvideo_locality"] = form?.["video_locality"];
    temp_movie_category["tempresort_type"] = form?.["resort_type"];
    temp_movie_category["tempbooking_type"] = form?.["booking_type"];
    temp_movie_category["tempfacility_type"] = form?.["facility_type"];
    temp_movie_category["tempfood_type"] = form?.["food_type"];

    temp_movie_category["tempvideo_language"] = form?.["video_language"];
    temp_movie_category["video_locality"] = Locality?.data
      ?.map(
        (option) => form["video_locality"]?.includes(option?.locality_name) && option.id
      )
      .filter((e) => e);
      temp_movie_category["resort_type"] = resort_type?.data
      ?.map(
        (option) => form["resort_type"]?.includes(option?.resort_type_name) && option.id
      )
      .filter((e) => e);
      temp_movie_category["booking_type"] = booking_option?.data
      ?.map(
        (option) => form["booking_type"]?.includes(option?.booking_option) && option.id
      )
      .filter((e) => e);
      temp_movie_category["facility_type"] = facilities?.data
      ?.map(
        (option) => form["facility_type"]?.includes(option?.facility_type) && option.id
      )
      .filter((e) => e);
      temp_movie_category["food_type"] = food_option?.data
      ?.map(
        (option) => form["food_type"]?.includes(option?.food_type) && option.id
      )
      .filter((e) => e);
    temp_movie_category["video_language"] = language?.data
      ?.map(
        (option) =>
          form?.["video_language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);

    temp_movie_category["created_by"] = user?.id;
    setForm({
      ...temp_movie_category,
    });

    Object.keys(form)?.map((key) => data.append(key, form?.[key]));
    const temp_form1 = form;
    temp_form1["resort_type"] = form?.["tempresort_type"];
    temp_form1["booking_type"] = form?.["tempbooking_type"];
    temp_form1["facility_type"] = form?.["tempfacility_type"];
    temp_form1["food_type"] = form?.["tempfood_type"];
    temp_form1["video_locality"] = form?.["tempvideo_locality"]
    temp_form1["video_category"] = form?.["tempvideo_category"];
    temp_form1["video_tag"] = form?.["tempvideo_tag"];
    temp_form1["video_language"] = form?.["tempvideo_language"];
    setForm({
      ...temp_form1,
    });

    const temp = tableData;
    if (isEdit) {
      dispatch(movie_update(data));
    } else {
      dispatch(video_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({ status: "Active" , smoking_zone:"Yes" });

      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_movie_list(data));
      }, 900);
      navigate("/Video/Video", { state: { view: "view_all" } });
    } else {
      setForm({ ...form });
    }
  }, [message]);
  const handleSubmitGenre = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(formGenre)?.map((key) => data.append(key, formGenre?.[key]));
    data.append("user", user?.id);
    formStructureGenre?.map((element) => {
      if (
        element.type == "image" &&
        formGenre?.[element.name] &&
        typeof formGenre?.[element.name] != "string"
      ) {
        const temp = formGenre;
        temp["temp" + element.name] = formGenre?.[element.name];
        temp[element.name] = URL.createObjectURL(formGenre?.[element.name]);

        setFormGenre({
          ...temp,
        });
      }
    });
    dispatch(genre_create(data));
    setFormGenre({})
    setOpenPopUp(false);
  };
  useMemo(()=>{
    if(genre_msg?.statuscode == 200){
      dispatch(all_genre_list())
      // setFormStructureCast()
    }
   },[genre_msg])
  return (
    <>
      <PopupForm
        open={openPopUp}
        setOpen={setOpenPopUp}
        // content={content}
        formStructure={formStructureGenre}
        handleSubmit={handleSubmitGenre}
        formTitle={"Create Tags"}
        key={"Form"}
        setForm={setFormGenre}
        form={formGenre}
        // tableData={tableData}
        // setTableData={setTableData}
        // isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Video/CreateVideo"}
        view_all={"/Video/Video"}
        form={form}
        export_excel={
          <Export
            fileName={"Videos"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead?.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Video/EditVideo"}
        />
      )}
    </>
  );
}
