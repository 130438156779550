import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  genre_create,
  genre_delete,
  genre_update,
  all_genre_list,
} from "../../../actions/Masters/genre";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { resort_type_create, resort_type_list, resort_type_update } from "../../../actions/Masters/resortType";
import { facilities_create, facilities_list, facilities_update } from "../../../actions/Masters/facilitie";
import { food_option_create, food_option_list, food_option_update } from "../../../actions/Masters/foodOption";
export default function FoodOption() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[3];
  useEffect(() => {
    setView(path != "FoodOption" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditFoodOption");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Food Option",
    deleteRecord: genre_delete,
    updateRecord: resort_type_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "food_type",
        numeric: false,
        disablePadding: true,
        label: "Resort type",
      },
      // {
      //   id: "genre_image",
      //   label: "Image",
      //   isImage: true,
      // },

      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
  });
  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      title: "Food Type",
      name: "food_type",
      maxLength: 30,
      // regex: /^[a-zA-Z\s\&\-]+$/,
      required: true,
    },

    { type: "undefined" },
    // {
    //   id: "3",
    //   type: "image",
    //   title: "Image",
    //   name: "tag_image",
    //   subtitle: "(Resolution : 512px x 512px) *",
    //   subsubsubtitle: "Support only JPG,PNG,JPEG",
    //   subsubtitle: "Max File Size 1MB",
    // },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ];

  const food_option = useSelector((state) => state?.masters?.food_option);
  useEffect(() => {
    dispatch(food_option_list());
  }, []);
  useMemo(() => {
    if (food_option?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = food_option?.data;
      setTableData({ ...temp });
    }
  }, [food_option]);

  const formTitle = isEdit ? "Edit Food Option" : "Create Food Option";

  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    const temp = tableData;
    if (isEdit) {
      dispatch(food_option_update(data));
    } else {
      dispatch(food_option_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(food_option_list());
		navigate("/Masters/FoodOption/FoodOption", { state: { view: "view_all" } })
				setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/FoodOption/CreateFoodOption"}
        view_all={"/Masters/FoodOption/FoodOption"}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/FoodOption/EditFoodOption"}
        />
      )}
    </>
  );
}
