import { useLocation } from "react-router-dom";

export const TableData = () => {
  const location = useLocation();
  // console.log(typeof(location?.state),"++++++++++")

  return {
    tableTitle: "Videos",
    onDeleteText: "Are you Sure?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "video_name1",
        label: "Resort Name",
        link: "/Video/VideoDetails",
        color: "var(--gradientColor2)",
        // subText: "video_subcategory",
      },
      {
        id: 'resort_type',
        label: 'Type',
        // subText:"publish_time1"
      },
      {
        id: "video_poster_1",
        label: "Image",
        isImage: true,
      },
     
      // {
      //   id: "genre",
      //   label: "Tags",
      //   subText1: "genre1",
      // },
      
      {
        id : "video_end_date",
        label:"End Date"
      },
     
      {
        id: "video_language",
        label: "Language",
      },
      // {
      //   id: "rental_count",
      //   label: "Total Rental",
      //   align: "center",
      // },

      // {
      //   id: "movieViews",
      //   label: "Views",
      //   align: "center",
      // },
      

      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,

      },

      {
        id: "edit",
        label: "Update",
      },
    ].filter((e) => e),
    tableBody: [],
    filterColumn: [
    
      // {
      //   title: undefined
      // },
      // {
      //   id: "2",
      //   title: "Language",
      //   name: "video_language",
      //   options: ["English", "Hindi", "Gujarati"],
      // },
      // {
      //   id: "3",
      //   title: "Category",
      //   name: "video_category",
      //   default:
      //     typeof location?.state != "object" && location.state == null
      //       ? location?.state
      //       : null,
      //   options: ["Action", "Comedy", "Drama", "Horror"],
      // },
      // {
      //   id: "3",
      //   title: "Sub Category",
      //   name: "video_subcategory",
      //   options: ["Action", "Comedy", "Drama", "Horror"],
      //   displayOn: "video_category",
      // },
      
     
    ],
  };
};
