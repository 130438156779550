import * as actionType from "../constants/actionTypes";

const mastersReducer = (state = { mastersData: null }, action) => {
  switch (action.type) {
    case actionType.CATEGORIES:
      return { ...state, categories: action.payload };
    case actionType.SUBCATEGORIES:
      return { ...state, subcategories: action.payload };
    case actionType.CASTS:
      return { ...state, casts: action.payload };
    case actionType.AREALIST:
      return {...state ,area_list : action.payload}
    case actionType.GENRE:
      return { ...state, genre: action.payload };
    case actionType.STATELIST:
      return { ...state, state_list: action.payload };
    case actionType.CITYLIST:
      return { ...state, city_list: action.payload };
    case actionType.LANGUAGES:
      return { ...state, languages: action.payload };
    case actionType.COUNTRY:
      return { ...state, country: action.payload };
    case actionType.MESSAGE5:
      return { ...state, genre_msg: action.payload };
    case actionType.LOCALITY:
      return { ...state, locality_list : action.payload}
    case actionType.RTYPE:
      return {...state , resort_type : action.payload}
    case actionType.FACILITIES:
      return {...state , facilities : action.payload}
    case actionType.FOODOPTIONS :
      return {...state , food_option : action.payload}
    case actionType.BOOKINGOPTIONS :
      return {...state , booking_option : action.payload}
    default:
      return state;
  }
};

export default mastersReducer;
