import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import "./ProductDetailsContent.css";
import { IMAGE } from "./../../api/index";
import { Box } from "@mui/material";
const ProductProfile = ({ data }) => {
  return (
    <>
      <Card
        sx={{
          textAlign: "center",
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px 1px",
          mb: "15px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            // <img
            // 			src={IMAGE+data?.video_thumbnail}
            // 			alt="Member"
            // 			height="132px"
            // 			style={{ borderRadius: "10px" }}
            // 		/>
          }
          <img
            src={IMAGE + data?.video_poster_1}
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />
          {data?.video_poster_2 && (
            <img
              src={IMAGE + data?.video_poster_2}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_3 && (
            <img
              src={IMAGE + data?.video_poster_3}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_4 && (
            <img
              src={IMAGE + data?.video_poster_4}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_5 && (
            <img
              src={IMAGE + data?.video_poster_5}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            // <img
            // 			src={IMAGE+data?.video_thumbnail}
            // 			alt="Member"
            // 			height="132px"
            // 			style={{ borderRadius: "10px" }}
            // 		/>
          }
        { data?.video_poster_6 && <img
            src={IMAGE + data?.video_poster_6}
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
          {data?.video_poster_7 && (
            <img
              src={IMAGE + data?.video_poster_7}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_8 && (
            <img
              src={IMAGE + data?.video_poster_8}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_9 && (
            <img
              src={IMAGE + data?.video_poster_9}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
          {data?.video_poster_10 && (
            <img
              src={IMAGE + data?.video_poster_10}
              alt="Image"
              height="180px"
              width="250px"
              style={{ borderRadius: "10px" }}
            />
          )}
        </div>
        <Typography
          as="h4"
          sx={{
            fontSize: 16,
            fontWeight: 500,
            mt: "10px",
          }}
        >
          {data?.video_name.length <= 50
            ? data?.video_name
            : data?.video_name.substring(0, 50) + "..."}
        </Typography>

        {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              mt: "10px",
            }}
          >
            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Downloads
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
              {data?.total_downloads }  
              </Typography>
            </Box>

            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Views
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
                { data?.movieViews}
              </Typography>
            </Box>
          </Box> */}
      </Card>
    </>
  );
};

export default ProductProfile;
