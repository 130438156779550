import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

const breadcrumbMap = {
  // 'Dashboard': {name:'Inbox',path:"/Dashboard/Dashboard"},
  'Video': {name:'Videos',path:"/Video/Video"},
  'CreateVideo': {name:'Create Videos',path:"/Video/CreateVideo"},
  'EditVideo': {name:'Edit Videos',path:"/Video/EditVideo"},
  'VideoSubmission' : {name : 'Video Submission' , path : '/VideoSubmission/VideoSubmission/'},
  'VideoSubmissionDetails' : {name : 'Video Submission Details' , path : '/VideoSubmission/VideoSubmissionDetails/'},

  // 'UpcomingMovie': {name:'Upcoming Videos',path:"/Movie/UpcomingMovie"},
  // 'CreateUpcomingMovie': {name:'Create Upcoming Videos',path:"/Movie/CreateUpcomingMovie"},
  // 'EditUpcomingMovie': {name:'Edit Upcoming Videos',path:"/Movie/EditUpcomingMovie"},

  'Series': {name:'Series',path:"/Series/Series/"},
  'CreateSeries': {name:'Create Series',path:"/Series/CreateSeries/"},
  'EditSeries': {name:'Edit Series',path:"/Series/EditSeries/"},

  'SeriesDetails': {name:'Series Details' , path:"/Series/Series/SeriesDetails/"},

  'Season': {name:'Season',path:"/Season/Season/"},
  'CreateSeason': {name:'Create Season',path:"/Season/CreateSeason/"},
  'EditSeason': {name:'Edit Season',path:"/Season/EditSeason/"},

  'WebEpisode': {name:'Web Episode',path:"/WebEpisode/WebEpisode/"},
  'CreateWebEpisode': {name:'Create Web Episode',path:"/WebEpisode/CreateWebEpisode/"},
  'EditWebEpisode': {name:'Edit Web Episode',path:"/WebEpisode/EditWebEpisode/"},
  'Episode': {name:'Episode',path:"/Episode/Episode/"},
  'CreateEpisode': {name:'Create Episode',path:"/Episode/CreateEpisode/"},
  'EditEpisode': {name:'Edit Episode',path:"/Episode/EditEpisode/"},
  'ResortManager' : {name : "Resort Managers" ,path : "/ResortManager/ResortManager/"},
  'ResortManagerDetail' : {name : "Resort Manager Details" ,path : "/ResortManagerDetail/ResortManagerDetail/"},


  'SSEpisode': {name:'Short Episode',path:"/SSEpisode/SSEpisode/"},
  'CreateSSEpisode': {name:'Create Short Episode',path:"/SSEpisode/CreateSSEpisode/"},
  'EditSSEpisode': {name:'Edit Short Episode',path:"/SSEpisode/EditSSEpisode/"},

  'Distributors' : {name:"Collaborator" , path :"/Distributors/Distributors/"},
  'CreateDistributors' : {name:"Create Collaborator" , path :"/Distributors/CreateDistributors/"},
  'EditDistributors' : {name:"Edit Collaborator" , path :"/Distributors/EditDistributors/"},

  'Highlight' : {name : "Highlight" , path:"/Highlight/Highlight/"},
  'CreateHighlight' : {name : "Create Highlight" , path:"/Highlight/CreateHighlight/"},
  'EditHighlight' : {name : "Edit Highlight" , path:"/Highlight/EditHighlight/"},

  'Promotion' : {name : "Promotions" , path:"/Promotion/Promotion/"},
  'CreatePromotion' : {name : "Create Promotion" , path:"/Promotion/CreatePromotion/"},
  'EditPromotion' : {name : "Edit Promotion" , path:"/Promotion/EditPromotion/"},

  'TVCategory': {name:'Streaming Category',path:"/LiveStreaming/TVCategory"},
  'TVChannel': {name:'Channel',path:"/LiveStreaming/TVChannel"},
  'SliderBanner': {name:'Slider Banner',path:"/SliderBanner/SliderBanner"},
  'CreateSliderBanner': {name:'Create Slider Banner',path:"/SliderBanner/CreateSliderBanner"},
  'EditSliderBanner': {name:'Edit Slider Banner',path:"/SliderBanner/EditSliderBanner"},

  'Customer': {name:'Customers',path:"/Customer/Customer"},
  'Transactions': {name:'Revenue Report',path:"/Transactions/Transactions/"},
  'AllTransaction' : {name :"All Transactions" , path:"/AllTransaction/AllTransaction"},
  'TransactionsDetails': {name:'Transactions Details',path:"/Transactions/TransactionsDetails/TransactionsDetails"},
  'WatchHours': {name:'Watch-hours',path:"/WatchHours/WatchHours/"},
  'MoviesRented': { name: 'Movies Rented', path: "/Customer/CustomerDetail/MoviesRented" },
  'Complaint': {name:'Complaints',path:"/Complaint/Complaint/"},
  'Subscriptions': {name:'Transactions',path:"/Subscriptions/Subscriptions/"},
  'EditSubscriptions': {name:'Edit Subscriptions',path:"/Subscriptions/EditSubscriptions/"},

  'Coupon': {name:'Promocode',path:"/Coupon/Coupon"},
  'PromocodeHistory': {name:'Promocode History',path:"/Coupon/PromocodeHistory"},
  'Advertisers': {name:'Advertisers',path:"/Advertisers/Advertisers/"},
  'Advertisement': {name:'Advertisement',path:"/Advertisers/Advertisement/"},
  'Masters': {name:'Masters',path:"/Masters/Category/"},
  'Avatar': {name:'Avatar',path:"/Masters/Avatar/"},
  'Category': {name:'Category',path:"/Masters/Category/Category/"},
  'CreateCategory': {name:'Create Category',path:"/Masters/Category/CreateCategory/"},
  'EditCategory': {name:'Edit Category',path:"/Masters/Category/EditCategory/"},

  'DistributorsFormData' : {name:"Distributors" , path:"/DistributorsFormData/DistributorsFormData/"},
  'DistributorsFormDetails' : {name:'form Details' ,path :"/DistributorsFormData/DistributorsFormDetails/DistributorsFormDetails/"},

  'SubCategory': {name:'Sub Category',path:"/Masters/SubCategory/SubCategory"},
  'CreateSubCategory': {name:'Create Sub Category',path:"/Masters/SubCategory/CreateSubCategory"},
  'EditSubCategory': {name:'Edit Sub Category',path:"/Masters/SubCategory/EditSubCategory"},

  'Language': {name:'Language',path:"/Masters/Language/Language"},
  'CreateLanguage': {name:'Create Language',path:"/Masters/Language/CreateLanguage"},
  'EditLanguage': {name:'Edit Language',path:"/Masters/Language/EditLanguage"},

  'ResortType': {name:'Resort Type',path:"/Masters/ResortType/ResortType"},
  'CreateResortType': {name:'Create Resort Type',path:"/Masters/ResortType/CreateResortType"},
  'EditResortType': {name:'Edit Resort Type',path:"/Masters/ResortType/EditResortType"},

  'Facilities': {name:'Facilities',path:"/Masters/Facilities/Facilities"},
  'CreateFacilities': {name:'Create Facilities',path:"/Masters/Facilities/CreateFacilities"},
  'EditFacilities': {name:'Edit Facilities',path:"/Masters/Facilities/EditFacilities"},

  'FoodOption': {name:'Food Option',path:"/Masters/FoodOption/FoodOption"},
  'CreateFoodOption': {name:'Create Food Option',path:"/Masters/FoodOption/CreateFoodOption"},
  'EditFoodOption': {name:'Edit Food Option',path:"/Masters/FoodOption/EditFoodOption"},

  'Locality': {name:'Locality',path:"/Masters/Locality/Locality"},
  'CreateLocality': {name:'Create Locality',path:"/Masters/Locality/CreateLocality"},
  'EditLocality': {name:'Edit Locality',path:"/Masters/Locality/EditLocality"},

  'City': {name:'City',path:"/Masters/City/City"},
  'CreateCity': {name:'Create City',path:"/Masters/City/CreateCity"},
  'EditCity': {name:'Edit City',path:"/Masters/City/EditCity"},

  'Area': {name:'Area',path:"/Masters/Area/Area"},
  'CreateArea': {name:'Create Area',path:"/Masters/Area/CreateArea"},
  'EditArea': {name:'Edit Area',path:"/Masters/Area/EditArea"},

  'Tag': {name:'Tags',path:"/Masters/Tag/Tag"},
  'CreateTag': {name:'Create Tag',path:"/Masters/Tag/CreateTag"},
  'EditTag': {name:'Edit Tag',path:"/Masters/Tag/EditTag"},

  
  'Cast': {name:'Cast',path:"/Masters/Cast/Cast/"},
  'CreateCast': {name:'Create Cast',path:"/Masters/Cast/CreateCast/"},
  'EditCast': {name:'Edit Cast',path:"/Masters/Cast/EditCast/"},

  'Country': {name:'Country',path:"/Masters/Country/Country/"},
  'CreateCountry': {name:'Create Country',path:"/Masters/Country/CreateCountry/"},
  'EditCountry': {name:'Edit Country',path:"/Masters/Country/EditCountry/"},

  'Subscriptions': { name: 'Subscriptions', path: "/Subscriptions/Subscriptions/" },
  'CreateSubscriptions': { name: 'Create Subscriptions', path: "/Subscriptions/CreateSubscriptions/" },
  'EditSubscriptions': { name: 'Edit Subscriptions', path: "/Subscriptions/EditSubscriptions/" },
  
  'SubAdmin': {name:'Sub Admin',path:"/Masters/SubAdmin/"},
  'Settings': {name:'Settings',path:"/Settings/AboutUs/"},
  'AboutUs': {name:'About Us',path:"/Settings/AboutUs/"},
  'PrivacyPolicy': {name:'Privacy Policy',path:"/Settings/PrivacyPolicy/"},
  'TermsConditions': {name:'Terms & Conditions',path:"/Settings/TermsConditions/"},
  'RefundPolicy': {name:'Refund Policy',path:"/Settings/RefundPolicy/"},
  'ContactUs': {name:'Contact Us',path:"/Settings/ContactUs/"},
  'AppSetting': {name:'App Setting',path:"/Settings/AppSetting/"},
  'UserLogs': {name:'User Logs',path:"/Settings/UserLogs/"},
  'VideoDetails': {name:'Video Details',path:"/Video/VideoDetails"},
  'EpisodeDetails': {name:'Web Series Episode Details',path:"/WebEpisode/WebEpisode/EpisodeDetails"},
  'EpisodeDetails': {name:'Super Short Episode Details',path:"/SSEpisode/SSEpisode/EpisodeDetails"},
  'WebSeries': {name:'TV Shows',path:"/WebSeries/Series/"},
  'CustomerDetail': {name:'Customer Detail',path:"/Customer/CustomerDetail/CustomerDetail"},
  'LiveStreaming': {name:'Live Streaming',path:"/LiveStreaming/TVCategory"},
  'MoviesDownloaded': {name:'Movies Downloaded',path:"/Customer/CustomerDetail/MoviesDownloaded"},
  'MoviesWatched': {name:'Movies Watched',path:"/Customer/CustomerDetail/MoviesWatched"},
  'MoviesDownload': {name:'Movies Download',path:"/Customer/CustomerDetail/MoviesDownload"},
  'Profile': {name:'Profile',path:"/Dashboard/Profile/"},
  // 'Layout': {name:'',path:"/Dashboard/Dashboard"},
  'Notifications': {name:'Notifications',path:"/Notifications/Notifications"},
  'CreateNotifications': {name:'Create Notifications',path:"/Notifications/CreateNotifications"},
  'EditNotifications': {name:'Edit Notifications',path:"/Notifications/EditNotifications"},

  
};



function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

function Page() {
  const location = useLocation();
  const paths = location.pathname.split('/').filter((x) => x);
  const pathnames = paths[0]=="Dashboard"?[]:[...new Set(paths)];

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <LinkRouter underline="hover" color="inherit" to="/Dashboard/Dashboard">
        Dashboard
      </LinkRouter>
      {/* {pathnames.map((value, index) => {
        const last = index >= pathnames.length - 2;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {pathnames[index]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {pathnames[index]}
          </LinkRouter>
        );
      })} */}
      {pathnames.map((ele,index)=>index!=pathnames.length-1?
      <LinkRouter underline="hover" color="inherit" to={breadcrumbMap?.[ele].path} key={breadcrumbMap?.[ele].path}>
      {breadcrumbMap?.[ele].name}
          </LinkRouter>:
          
<Typography color="text.primary" key={breadcrumbMap?.[ele].path}>
            {breadcrumbMap?.[ele].name}
          </Typography>)
          }
    </Breadcrumbs>
  );
}

export default function RouterBreadcrumbs() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (

      <Box sx={{ display: 'flex', flexDirection: 'column',ml:2 }} fontSize={"12px"}>
        <Routes>
          <Route  path="*" element={<Page />} />
        </Routes>
     
      </Box>
   
  );
}
