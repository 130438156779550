
import { BOOKINGOPTIONS, FOODOPTIONS, MESSAGE } from '../../constants/actionTypes';
import * as api from '../../api/index.js';

export const food_option_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.food_option_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const food_option_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.food_option_update(formData);
      dispatch({ type: MESSAGE, payload:data });
    } catch (error) {
      console.log(error);
    }
  };
  // export const genre_delete = (formData) => async (dispatch) => {
  //   try {
  //     const { data } = await api.genre_delete(formData);
  //     dispatch({ type: MESSAGE5, payload:data });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  export const food_option_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.food_option_list(formData);
      
      dispatch({ type: FOODOPTIONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const book_option_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.book_option_list(formData);
      
      dispatch({ type: BOOKINGOPTIONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  