import axios from 'axios';

// const API = axios.create({ baseURL: 'https://api.icode49.com/api/' });
// export const IMAGE = 'https://api.icode49.com/'

// const API = axios.create({ baseURL: 'https://api.locality360.in/api/'});
// export const IMAGE = 'https://api.locality360.in/'

const API = axios.create({ baseURL: 'https://api.resortrental.in/api/'});
export const IMAGE = 'https://api.resortrental.in/'







// Master->Category
export const category_create = (formData) => API.post('/category_create/', formData);
export const category_update = (formData) => API.post('/category_update/', formData);
export const category_delete = (formData) => API.post('/category_delete/', formData);
export const all_category_list = (formData) => API.post('/all_category_list/', formData);


// Master->Sub Category
export const subcategory_create = (formData) => API.post('/subcategory_create/', formData);
export const subcategory_update = (formData) => API.post('/subcategory_update/', formData);
export const subcategory_delete = (formData) => API.post('/subcategory_delete/', formData);
export const all_subcategory_list = (formData) => API.post('/all_subcategory_list/', formData);

// Master->Resort Type
export const resort_type_create = (formData) => API.post('/resort_type_create/',formData);
export const resort_type_list = (formData) => API.post('/resort_type_list/' , formData);
export const resort_type_update = (formData) => API.post('/resort_type_update/' ,formData);

// Master->Facilities
export const facilities_create = (formData) => API.post('/facilities_create/',formData);
export const facilities_update = (formData) => API.post('/facilities_update/' , formData);
export const facilities_list = (formData) => API.post('/facilities_list/' ,formData);

// Master-> Booking Option
export const book_option_list = (formData) => API.post('/booking_option_list/' ,formData)

// Master->Food Options
export const food_option_create = (formData) => API.post('/food_option_create/',formData);
export const food_option_update = (formData) => API.post('/food_option_update/' , formData);
export const food_option_list = (formData) => API.post('/food_option_list/' ,formData);

// Master-> Country
export const country_create = (formData) => API.post('/country_create/',formData);
export const country_update = (formData) => API.post('/country_update/',formData);
export const all_country_list = (formData) => API.post('/all_country_list/',formData);
export const country_delete = (formData) => API.post('/country_delete/',formData);

// Master->Cast
export const cast_create = (formData) => API.post('/cast_create/', formData);
export const cast_update = (formData) => API.post('/cast_update/', formData);
export const cast_delete = (formData) => API.post('/cast_delete/', formData);
export const all_cast_list = (formData) => API.post('/all_cast_list/', formData);

// Master -> Area,City
export const state_list = (formData) => API.post('/state_list/',formData);
export const city_create = (formData) => API.post('/city_create/',formData);
export const city_list = (formData) => API.post('/city_list/' , formData);
export const area_create = (formData) => API.post('/area_create/' , formData);
export const area_list = (formData) => API.post('/area_list/' , formData);
export const city_delete = (formData) => API.post('/city_delete/' , formData);
export const area_delete = (formData) => API.post('/area_delete/' , formData);
export const area_update = (formData) => API.post('/area_update/' , formData)

//Master -> Locality

export const locality_create = (formData) => API.post('/locality_create/' ,formData)
export const locality_update = (formData) => API.post('/locality_update/' ,formData)
export const locality_delete = (formData) => API.post('/locality_delete/' ,formData)
export const locality_list = (formData) => API.post('/locality_list/' ,formData)



// highlight
export const highlight_create = (formData) => API.post('/highlight_create/', formData);
export const highlight_update = (formData) => API.post('/highlight_update/', formData);
export const highlight_delete = (formData) => API.post('/highlight_delete/', formData);
export const all_highlight_list = (formData) => API.post('/all_highlight_list/', formData);

//promotions
export const quick_promotion_create = (formData) => API.post('/promotion_create/', formData);
export const quick_promotion_update = (formData) => API.post('/promotion_update/', formData);
export const quick_promotion_delete = (formData) => API.post('/promotion_delete/', formData);
export const all_quick_promotion_list = (formData) => API.post('/all_promotion_list/', formData);


// Master->Genre
export const genre_create = (formData) => API.post('/tag_create/', formData);
export const genre_update = (formData) => API.post('/tag_update/', formData);
export const genre_delete = (formData) => API.post('/tag_delete/', formData);
export const all_genre_list = (formData) => API.post('/all_tag_list/', formData);

// Master->Language
export const language_create = (formData) => API.post('/language_create/', formData);
export const language_update = (formData) => API.post('/language_update/', formData);
export const language_delete = (formData) => API.post('/language_delete/', formData);
export const all_language_list = (formData) => API.post('/all_language_list/', formData);


// Producer Form 
export const producer_movie_form_create = (formData) => API.post('/producer_movie_form_create/' , formData)
export const all_producer_movie_form_list = (formData) =>API.post('/all_producer_movie_form_list/' , formData)
export const producer_movie_detail_form = (formData) =>API.post('/producer_movie_detail_form/' ,formData)


// Notification
export const notification_create = (formData) => API.post('/notification_create/', formData);
export const notification_update = (formData) => API.post('/notification_update/', formData);
export const notification_delete = (formData) => API.post('/notification_delete/', formData);
export const all_notification_list = (formData) => API.post('/all_notification_list/', formData);
export const notification_read_status_update = (formData) => API.post('/notification_read_status_update/', formData);
export const notification_unread_count = (formData) => API.post('/notification_unread_count/', formData);


// Producer 

export const producer_create = (formData) => API.post('/distributor_register/' , formData);
export const producer_update = (formData) => API.post('/distributor_update/' , formData);
// export const producer_list_movie = (formData) => API.post('/producer_list_movie')
export const producer_delete = (formData) => API.post('/distributor_delete/', formData); // Pending

export const producer_list_movie = (formData) => API.post('/producer_list_movie/' , formData)
export const all_producer_list = (formData) =>API.post('/all_distributor_list/' ,formData)

// Slider Banner
export const sliderbanner_create = (formData) => API.post('/slider_banner_create/', formData);
export const sliderbanner_update = (formData) => API.post('/slider_banner_update/', formData);
export const sliderbanner_delete = (formData) => API.post('/slider_banner_delete/', formData);
export const all_sliderbanner_list = (formData) => API.post('/all_slide_banner_list/', formData);



//Video
export const video_create = (formData) => API.post('/video_create/', formData);
export const movie_update = (formData) => API.post('/video_update/', formData);
export const movie_delete = (formData) => API.post('/video_delete/', formData);
export const all_movie_list = (formData) => API.post('/all_video_list/', formData);
export const movie_details = (formData) => API.post('/video_details/', formData);
export const movie_watch_user_list = (formData) => API.post('/video_watch_user_list/', formData);

// Video Submission 
export const video_submission_list = (formData) => API.post('/video_submission_list/' , formData);
export const video_submission_details = (formData) => API.post('/video_submission_details/' , formData)
export const video_submission_status_update = (formData) => API.post('/video_submission_status_update/',formData)

// Movie->Upcoming Movie
export const upcoming_movie_create = (formData) => API.post('/upcoming_movie_create/', formData);
export const upcoming_movie_update = (formData) => API.post('/upcoming_movie_update/', formData);
export const upcoming_movie_delete = (formData) => API.post('/upcoming_movie_delete/', formData); 
export const all_upcoming_movie_list = (formData) => API.post('/all_upcoming_movie_list/', formData);


// subtitle & audio file delete

export const subtitle_delete = (formData) => API.post('/subtitle_delete/' , formData);
export const audio_file_delete = (formData) => API.post('/audio_file_delete/' , formData)
export const country_price_delete_from_videos = (formData) => API.post('/country_price_delete_from_videos/' , formData)



// Web Series->Episode
export const episode_create = (formData) => API.post('/episode_create/', formData);
export const episode_update = (formData) => API.post('/episode_update/', formData);
export const episode_delete = (formData) => API.post('/episode_delete/', formData);
export const all_episode_list = (formData) => API.post('/all_episode_list/', formData);
export const episode_details= (formData) => API.post('/episode_details_admin/', formData); 
export const episode_watch_user_list= (formData) => API.post('/episode_watch_user_list/', formData);




// Web Series->Series
export const series_create = (formData) => API.post('/series_create/', formData);
export const series_update = (formData) => API.post('/series_update/', formData);
export const series_delete = (formData) => API.post('/series_delete/', formData);
export const all_series_list = (formData) => API.post('/all_series_list/', formData);
export const series_details_admin = (formData) => API.post('/series_details_admin/' , formData)


// Web Series -> Season 

export const season_create = (formData) => API.post('/season_create/',formData)
export const season_update = (formData) => API.post('/season_update/',formData)
export const season_delete = (formData) => API.post('/season_delete/',formData)
export const all_season_list = (formData) => API.post('/all_season_list/',formData)




// Customer
export const customer_update = (formData) => API.post('/user_status_update/', formData); // Pending
export const customer_delete = (formData) => API.post('/customer_delete/', formData); // Pending
export const all_customer_list = (formData) => API.post('/customer_list/', formData); // Pending
export const customer_details = (formData) => API.post('/customer_details/', formData); // Pending
export const active_user_list = (formData) =>API.post('/active_user_list/',formData)


// manager 
export const resort_manager_list = (formData) => API.post('/resort_manager_list/' , formData)
export const resort_manager_detail = (formData) =>API.post('/resort_manager_detail/',formData)


// Subscription
export const subscription_create = (formData) => API.post('/subscription_create/', formData);
export const subscription_update = (formData) => API.post('/subscription_update/', formData);
export const subscription_delete = (formData) => API.post('/subscription_delete/', formData);
export const all_subscription_list = (formData) => API.post('/all_subscription_details/', formData);



// Transaction
export const all_successfull_transaction = (formData) => API.post('/all_successfull_transaction/', formData); // Pending
export const all_transaction_list = (formData) => API.post('/transaction_list/', formData); // Pending
export const transaction_details = (formData) => API.post('/transaction_details/',formData)




// Watch Hours
export const all_watchhour_list = (formData) => API.post('/all_watch_hours_list/', formData); // Pending

// Complaints
export const all_complaint_list = (formData) => API.post('/all_complaint_list/', formData); // Pending
export const complaint_status_update = (formData) => API.post('/complaint_status_update/', formData); // Pending






// Dashboard
export const movies_data_dashboard = (formData) => API.post('/video_data_dashboard/', formData);
export const count_on_dashboard = (formData) => API.post('/count_on_dashboard/', formData);
export const total_user = (formData) => API.post('/total_user/', formData);
export const total_subscribe_user = (formData) => API.post('/total_subscribe_user/', formData);
export const monthly_revenue = (formData) => API.post('/monthly_revenue/', formData);
export const daily_revenue = (formData) => API.post('/daily_revenue/', formData);
export const total_revenue = (formData) => API.post('/tvod_revenue/', formData);
export const most_watch_tv_show_dashboard = (formData) => API.post('/most_watch_tv_show_dashboard/', formData);
export const recent_subscriber_dashboard = (formData) => API.post('/recent_subscriber_dashboard/', formData);
export const renewal = (formData) => API.post('/renewal/', formData);

//Authenticate
export const login = (formData) => API.post('/web_login/', formData);
export const forgot_password = (formData) => API.post('/forgot_password_send_otp/', formData);
export const change_password = (formData) => API.post('/change_password/', formData);
export const Otp_verify = (formData) => API.post("/forgot_password_verify_otp/",formData)
export const update_password = (formData) => API.post("/update_password/",formData)

//Setting-> About us
export const about_us = (formData) => API.post('/about_us/', formData);
export const about_us_update = (formData) => API.post('/about_us_update/', formData);

//Setting-> Refund Policy
export const refund_policy = (formData) => API.post('/refund_policy/', formData);
export const refund_policy_update = (formData) => API.post('/refund_policy_update/', formData);

//Setting-> Privacy Policy
export const privacy_policy = (formData) => API.post('/privacy_policy/', formData);
export const privacy_policy_update = (formData) => API.post('/privacy_policy_update/', formData);

//Setting-> Terms And Conditions
export const terms_and_conditions = (formData) => API.post('/terms_and_conditions/', formData);
export const terms_and_conditions_update = (formData) => API.post('/terms_and_conditions_update/', formData);


//import
export const bulk_import = (formData) => API.post('/bulk_import/', formData);


