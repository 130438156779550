import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import "./VideoSubmissionDetails.css";
import { IMAGE } from "./../../api/index";
import { Box } from "@mui/material";
const VideoProfile = ({ data }) => {
  return (
    <>
      <Card
        sx={{
          textAlign: "center",
          boxShadow: "none",
          borderRadius: "10px",
          p: "25px 1px",
          mb: "15px",
          // background:"black"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        { 
        // <img
				// 			src={IMAGE+data?.video_thumbnail}
				// 			alt="Member"
				// 			height="132px"
				// 			style={{ borderRadius: "10px" }}
				// 		/>
}
{/* {data && data?.map((ele ,index)=>  */}

          <img
            src={
              IMAGE +
              (data?.[0]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />
        { data?.[1] && <img
            src={
              IMAGE +
              (data?.[1]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
         {data?.[2] && <img
            src={
              IMAGE +
              (data?.[2]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
         {data?.[3] && <img
            src={
              IMAGE +
              (data?.[3]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
         {data?.[4] && <img
            src={
              IMAGE +
              (data?.[4]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
{/* )} */}
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        { 
        // <img
				// 			src={IMAGE+data?.video_thumbnail}
				// 			alt="Member"
				// 			height="132px"
				// 			style={{ borderRadius: "10px" }}
				// 		/>
}
{/* {data && data?.map((ele ,index)=>  */}

        { data?.[5] && <img
            src={
              IMAGE +
              (data?.[5]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
         { data?.[6] &&<img
            src={
              IMAGE +
              (data?.[6]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
        { data?.[7] && <img
            src={
              IMAGE +
              (data?.[7]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
          { data?.[8] &&<img
            src={
              IMAGE +
              (data?.[8]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
         {data?.[9] && <img
            src={
              IMAGE +
              (data?.[9]?.image)
            }
            alt="Image"
            height="180px"
            width="250px"
            style={{ borderRadius: "10px" }}
          />}
{/* )} */}
        </div>
        {/* <Typography
          as="h4"
          sx={{
            fontSize: 16,
            fontWeight: 500,
            mt: "10px",
          }}
        >
          { data?.video_name?.length <= 50
              ? data?.video_name
              : data?.video_name?.substring(0, 50) + "..."
           }
        </Typography> */}
     
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              mt: "10px",
            }}
          >
            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Downloads
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
              {data?.total_downloads }  
              </Typography>
            </Box>

            <Box>
              <Typography color="#A9A9C8" fontSize="13px">
                Views
              </Typography>
              <Typography fontWeight="500" fontSize="16px">
                { data?.movieViews}
              </Typography>
            </Box>
          </Box> */}
     
      </Card>
    </>
  );
};

export default VideoProfile;
