import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import ResortType from '../components/Masters/ResortType/ResortType';

function ResortTypeModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateResortType' element={<Layout children={ResortType()} />} />
                < Route path='/EditResortType' element={<Layout children={ResortType()} />} />
                < Route path='/ResortType' element={<Layout children={ResortType()} />} />
            </Routes>
        </div>
    )
}
export default ResortTypeModule