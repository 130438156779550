import React,{useEffect, useMemo, useState} from 'react';
import Grid from "@mui/material/Grid";
import Features from './Features';
import PersonalInfo from './PersonalInfo'
import MySubscriptions from './MySubscriptions';
import ProfileContent from './ProfileContent';
import { customer_details } from '../../actions/customer';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import ListTable from '../utils/Table';
import MoviesWatched from "./MoviesWatched"
import MoviesDownload from "./MoviesDownload"

export default function CustomerDetail() {
  const dispatch=useDispatch()
  const location = useLocation()
  const customer=useSelector((state)=>state.customers.customer)
  console.log(customer,customer?.redirect_video_list,"cccccc")
  useEffect(()=>{
    if(location.state?.id){

      const id = location.state?.id
      dispatch(customer_details({id:location.state?.id }))
    }
},[location])


  return (
    <>

    <PersonalInfo  data = {customer?.data} />
    <MoviesWatched  data = {customer?.video_seen_list} />
    {/* <MoviesDownload data = {customer?.redirect_video_list} /> */}




    </>
  );
}
