import React,{useEffect, useMemo, useState} from 'react';
import Grid from "@mui/material/Grid";
import Features from './Features';
import PersonalInfo from './PersonalInfo'
import MySubscriptions from './MySubscriptions';
import ProfileContent from './ProfileContent';
// import { customer_details } from '../../actions/customer';
import { useDispatch,useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import ListTable from '../utils/Table';
import MoviesWatched from "./MoviesWatched"
import MoviesDownload from "./MoviesDownload"
import { resort_manager_detail } from '../../actions/resory_manager';
// import { seller_detail_admin } from '../../actions/seller';

export default function ResortManagerDetail() {
  const dispatch=useDispatch()
  const location = useLocation()
  const resort_manager=useSelector((state)=>state.resort_manager.resort_manager_details)
  console.log(resort_manager,"cccccc")
  useEffect(()=>{
    if(location.state?.id){

      const id = location.state?.id
      dispatch(resort_manager_detail({id:location.state?.id }))
    }
},[location])


  return (
    <>

    <PersonalInfo data={resort_manager?.data}   />
    <MoviesWatched data = {resort_manager?.video_seen_detail}   />
    <MoviesDownload data = {resort_manager?.video_submission}  />




    </>
  );
}
