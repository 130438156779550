

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import ResortManagerDetail from '../components/ResortManagerDetail/ResortManagerDetail';

function ResortManagerDetailModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/ResortManagerDetail' element={<Layout children={(ResortManagerDetail())}/>}/>

{/* < Route path='/MoviesDownload' element={<Layout children={MoviesDownload()}/>}/> */}
{/* < Route path='/MoviesRented' element={<Layout children={MoviesRented()}/>}/> */}
{/* < Route path='/MoviesWatched' element={<Layout children={MoviesWatched()}/>}/> */}



      </Routes>
    </div>
  );
}

export default ResortManagerDetailModule;
