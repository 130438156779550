import * as actionType from "../constants/actionTypes";

const resortManagerReducer = (state = { managerData: null}, action) => {
	switch (action.type) {
		
		case actionType.RMANAGERS:
			return {...state,resort_manager_list: action.payload};
		case actionType.RMANAGER:
			return {...state,resort_manager_details: action.payload};
			// case actionType.MOVIE:
			// return {...state,movie: action.payload,showLoading:false};	
		default:
			return state;
	}
};

export default resortManagerReducer;
