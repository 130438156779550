export const FormStructure = (isEdit) => {
  return [
    [
      {
        id: "1",
        type: "select",
        title: "Resort Type",
        name: "resort_type",
        options: ["Portrait", "Landscape"],
        required: true,
      },
      {
        id: "2",
        type: "inputBox",
        title: "Resort Name",
        name: "video_name",
        maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        isCapitalise: true,
        required: true,
      },
      {
        id: "3",
        type: "select_multiple",
        title: "Facilities",
        // maxSelections: "5",
        name: "facility_type",
        options: ["Portrait", "Landscape"],
        // errorText: "You can select max 5 Facilit at a time",
        required: true,
      },

      {
        id: "4",
        type: "select",
        title: "Language",
        name: "video_language",
        options: ["English", "Hindi", "Gujarati"],
        required: true,
      },
      {
        id: "5",
        type: "select_multiple",
        title: "Food Types",
        name: "food_type",
        maxSelections: "5",
        options: ["English", "Hindi", "Gujarati"],
        errorText: "You can select max 5 Food Items at a time",
        required: true,
        // isPlusBtnOpen :true,
      },
      {
        id: "6",
        type: "select",
        title: "Publish Type",
        name: "publish_type",
        options: ["Now", "Later"],
        required: true,
      },
      {
        id: "7",
        type: "inputBox",
        variant: "date",
        title: "Publish Date",
        min: new Date().toISOString().split("T")[0],
        name: "release_date",
        display: "none",
        required: true,
      },
      {
        id: "8",
        type: "inputBox",
        variant: "time",
        title: "Publish Time",
        name: "publish_time",
        display: "none",
        required: true,
      },
      {
        id: "9",
        type: "select",
        title: "Select Locality",
        name: "video_locality",
        options: ["Now", "Later"],
        required: true,
      },
      // {
      //   id: "10",
      //   type: "select",
      //   title: "Select City",
      //   name: "city",
      //   options: ["Now", "Later"],
      //   required: true,
      // },
      // {
      //   id: "11",
      //   type: "select",
      //   title: "Select Area",
      //   name: "video_area",
      //   options: ["Now", "Later"],
      //   required: true,
      // },
      // {
      //   id: "9",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "Upload M3U8 URL",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "video_link_m3u8",
      //   // disabled:isEdit ? true :false,
      //   required: true,
      // },
      {
        id: "10",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Mp4 URL",
        endsWith: ".mp4",
        errorText: "File extension should be .mp4 only",
        name: "video_link_mp4",
        required: true,
      },
      {
        id: "11",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Add Catalogue URL",
        // endsWith: ".mp4",
        // errorText: "File extension should be .mp4 only",
        name: "catalogue",
      },
      {
        id: "12",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Add Store Location URL",
        // endsWith: ".mp4",
        // errorText: "File extension should be .mp4 only",
        name: "google_map_link",
        // required: true,
      },
      {
        id: "13",
        type: "inputBox",
        variant: "date",
        title: "Video End Date",
        min: (() => {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
          return tomorrow.toISOString().split("T")[0];
        })(),
        name: "video_end_date",
        required: true,
      },
      {
        id: "14",
        type: "select_multiple",
        title: "Select Booking Type",
        name: "booking_type",
        options: [],
        required: true,
      },
      {
        id: "15",
        type: "inputBox",
        title: "Weekend Price",
        name: "weekend_price",
        regex: /^[0-9\.]+$/,
        maxLength: "6",
        required: true,
      },
      {
        id: "16",
        type: "inputBox",
        title: "Holiday Price",
        name: "holiday_price",
        regex: /^[0-9\.]+$/,
        maxLength: "6",
        required: true,
      },
      {
        id: "17",
        type: "inputBox",
        title: "Week Days Price",
        name: "weekdays_price",
        regex: /^[0-9\.]+$/,
        maxLength: "6",
        required: true,
      },
      {
        id: "18",
        type: "inputBox",
        title: "Group Price",
        name: "group_price",
        regex: /^[0-9\.]+$/,
        maxLength: "6",
        required: true,
      },
      {
        id: "19",
        type: "inputBox",
        title: "Resort Address",
        name: "video_area",
        // regex: /^[0-9\.]+$/,
        // maxLength: "6",
        required: true,
      },
      {
        id: "20",
        type: "inputBox",
        title: "Nearest Highwat",
        name: "nearest_highway",
        // maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "21",
        type: "inputBox",
        title: "Nearest Bus Station",
        name: "nearest_bus_station",
        // maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "22",
        type: "inputBox",
        title: "Nearest Railway Stations",
        name: "nearest_railway_station",
        // maxLength: "100",
        // regex: /^[a-zA-Z0-9\s\&]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "23",
        type: "toggle",
        title: "Smoking",
        name: "smoking_zone",
        default: "Yes",
        // display: "none",
        // size: "12",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "24",
        type: "phone",
        title: "Mobile Number",
        maxLength: 12,
        name: "mobile_number",
        required: true,
      },
      {
        id: "25",
        type: "inputBox",
        title: "Description",
        name: "video_description",
        maxLength: "150",
        size: "12",
        isCapitalise: true,
        required: true,
      },
      {
        id: "26",
        type: "toggle",
        title: "Status",
        name: "status",
        default: "Active",
        display: "none",
        size: "12",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
     
      // {
      //   id: "14",
      //   type: "image",
      //   subtitle: "(Resolution : 1920px x 720px) *",
      //   subsubtitle: "Max File Size 1MB",
      //   subsubsubtitle: "Support only JPG,PNG,JPEG",
      //   title: "Video Thumbnail",
      //   name: "video_thumbnail",
      //   size: 4,
      //   // required: true,
      // },
      // {
      //   id: "19",
      //   type: "button",
      //   title: isEdit ? "Edit" : "Create",
      // },
    ],
    [
      {
        id: "1",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_1",
        size: 4,
        required: true,
      },
      {
        id: "2",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_2",
        size: 4,
        // required: true,
      },
      {
        id: "3",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_3",
        size: 4,
        // required: true,
      },
      {
        id: "4",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_4",
        size: 4,
        // required: true,
      },
      {
        id: "5",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_5",
        size: 4,
        // required: true,
      },
      {
        id: "6",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_6",
        size: 4,
        // required: true,
      },
      {
        id: "7",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_7",
        size: 4,
        // required: true,
      },
      {
        id: "8",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_8",
        size: 4,
        // required: true,
      },
      {
        id: "9",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_9",
        size: 4,
        // required: true,
      },
      {
        id: "10",
        type: "image",
        subtitle: "(Resolution : 1920px x 1080px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        title: "Resort Image",
        name: "video_poster_10",
        size: 4,
        // required: true,
      },
    ],
  ];
};
